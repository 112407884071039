@import url("https://fonts.googleapis.com/css?family=Work+Sans:200");

/*
** app.css
**
**  Application defined styles.
*/

/*
**  Generic CSS
*/

body,
html {
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Arial, Helvetica, sans-serif;
    font-smooth: auto;
    height: 100%;
}

body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #f8f8f8;
}

body.app {
    overflow: hidden;
}

*,
a,
button {
    outline: none !important;
}

*,
:after,
:before {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

#root {
    height:100%;
  /* display: flex;
  flex-direction: column;
  height: 100vh; */
}

a {
    color: #666666;
}

a:hover {
    color: #279f9f;
    text-decoration: none;
}

/*
**  Application Layout 
*/
.app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    height:100%;
}

.app-main {
    position: relative;
    min-height: 100vh;
    background-color: #fafafa;
}

body .app-header {
    position: sticky;
    height: 100px;
    display: flex;
    flex-direction: column;
}

body .app-footer {
    padding: 4em 4em 4em 4em;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #fafafa;
    text-align: center;
}

body .app-sidebar-wrapper {
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 60;
    position: static;
    width: 270px;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

body .app-sidebar-wrapper-fixed {
    position: fixed;
}

body .app-sidebar-header {
    height: 74px;
    width: 270px;
    display: flex;
    align-items: center;
    background: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

@media (min-width: 1200px) {
    body .app-sidebar-header {
        height: 0px;
    }
}

body .header-logo-text {
    padding-left: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    width: auto;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

body .app-sidebar-menu {
    height: calc(100vh - 0px);
    /* Subtract the height of the sidebar header */
}

body .app-sidebar-userbox {
    background: rgba(1, 137, 159, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    margin: 0;
    border-radius: 0;
    text-align: center;
    white-space: nowrap;
}

body .app-sidebar-userbox .app-sidebar-userbox-avatar {
    width: 54px;
    height: 54px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    box-shadow:
    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

}

body .app-sidebar-userbox .app-sidebar-userbox-name {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    height: auto;
    white-space: wrap;
}

body .app-sidebar-userbox .app-sidebar-userbox-description {
    opacity: 0.7;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

body .app-sidebar-item {
    display: block;
    padding: 2px 1rem 1px 0;
}

body .app-sidebar-button {
    padding-top: 0.41667rem;
    padding-bottom: 0.41667rem;
    padding-right: 0.5rem;
    justify-content: flex-start;
    text-transform: none;
    border-radius: 0 22px 22px 0;
    font-size: 15px;
    width: 100%;
    font-weight: 400;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #263055;
    white-space: nowrap;
}

body .app-sidebar-button.active,
body .app-sidebar-button:hover {
    color: #01899f;
    background: rgba(1, 137, 159, 0.05);
}

body .app-sidebar-icon {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

body .sidebar-expand-icon {
    margin-left: auto;
    height: 16px;
    width: 16px;
    opacity: 0.6;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

body .sidebar-expand-icon-rotate {
    opacity: 0.9;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

body .app-sidebar-button-wrapper {
    display: inline-flex;
    position: relative;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    padding: 4px 17px;
    padding-left: 52px;
    border: 0;
    border-radius: 0px;
    border-start-end-radius: 15px;
    border-end-end-radius: 15px;
    text-transform: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
    font-size: 14px;
    color: #263055;
    min-width: 64px;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    cursor: pointer;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    background-color: transparent;
}

body .app-sidebar-button-wrapper:hover {
    background-color: #f6f6f6;
}

body .app-sidebar-button-wrapper.active {
    background-color: #01899f;
    color: #ffffff;
    font-weight: 500;
}

.app-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding: 0;
}

.app-content-sidebar-fixed {
    min-height: 100vh;
}

@media (min-width: 1200px) {
    .app-content-sidebar-fixed {
        padding-left: 270px;
    }
}

.app-content--inner {
    display: flex;
    flex: 1 1;
}

/* .app-content--inner__wrapper {
  display: block;
  width: 100%;
} */

.app-page-title {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: hsla(0, 0%, 100%, 0.5);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: relative;
}

.app-page-title--first {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.app-page-title--mobilemenu {
    display: block;
    padding-right: 2em;
}

.app-page-title--mobilemenu>* {
    font-size: 2.5em !important;
}

@media (min-width: 1200px) {
    .app-page-title--mobilemenu {
        display: none;
    }
}

.app-page-title--iconbox {
    background: #fff;
    color:#aaaaaa;
    border-radius: 100%;
    display: none;
    margin: 0 1rem 0 0;
    justify-content: center;
    align-items: center;
}

.app-page-title--iconbox > * {
    display: block;
    max-width: 75px;
    max-height: 75px;
    width: auto;
    height: auto;
}

.app-page-title--iconbox.subtitled > * {
    height: 75px !important;
    width: 75px !important;
}

@media (min-width: 600px) {
    .app-page-title--iconbox {
        display: flex;
    }
}

.app-page-title--heading {
    padding-right: 1rem;
    flex-grow: 1;
}

.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    touch-action: auto;
}

.scrollbar-container {
    position: relative;
    height: 100%;
}

/*
** Main content section classes
*/

/* main[role="main"] {
  flex-grow: 1;
} */

.slim {
    margin-left: auto !important;
    margin-right: auto !important;
}

section.tabbed {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px;
    padding: 0px;
}

@media (max-width: 992px) {
    .slim {
        max-width: 696px;
    }
}

@media (min-width: 1200px) {
    .slim {
        max-width: 1080px;
    }
}

/*
**  Tab column layout
*/
section .tabbed {
    display: flex;
}

section .tabbed .navColumn {
    box-sizing: border-box;
    flex-basis: 285px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

section .tabbed .navColumn .title {
    color: #495057;
    font-size: 1.25rem;
}

section .tabbed .navColumn .tabs {
    padding-left: 2em;
}

section .tabbed .navColumn .tabs ul {
    padding-left: 0;
    list-style-type: none;
}

section .tabbed .navColumn .tabs ul li.header {
    font-size: 1.2rem;
    font-weight: 200;
    margin-top: 0.5em;
}

section .tabbed .navColumn .tabs ul li {
    font-size: 0.9rem;
    padding-bottom: 0.5em;
    margin: 0;
    color: #666666;
}

section .tabbed .navColumn .tabs .tab {
    padding: 5px 0;
    margin: 25px 0;
}

section .tabbed .navColumn .tabs .tab.current {
    color: #279f9f;
    background-color: rgba(39, 159, 159, 0.1);
    border-color: solid 1px #279f9f;
    border-radius: 10px;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    left: -1em;
}

section .tabbed .content {
    box-sizing: border-box;
    height: 100%;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-grow: 1;
}

/*
*  Flex shortcuts
*/

.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexColumnRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flexColumnRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.flexRow {
    display: flex;
    justify-content: flex-start;
}

.flexRowCenter {
    display: flex;
    justify-content: center;
}

.flexRowStretch {
    display: flex;
    justify-content: space-between;
}

.flexRowPushRight {
    display: flex;
    justify-content: flex-end;
}

.flexWrap {
    flex-wrap: wrap;
}

@media (max-width: 992px) {
    .flexStretch {
        flex-wrap: wrap;
    }
}

/*
**  Information from deleted objects
*/

.deleted {
    text-decoration: line-through;
    color: #999999;
    font-weight: 100;
}

/*
** Feature - used on cards
*/
.feature {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 2em;
}

.feature .title {
    display: block;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 0.75em;
}

.feature .name {
    font-size: 0.95em;
    white-space: nowrap;
}

/*
**  Cards
*/

.highlight:hover {
    background-color: #f8f8f8;
    color: #666666;
    text-decoration: none;
}

.smCard {
    min-width: 250px;
    flex-basis: 100%;
}

.mdCard {
    min-width: 450px;
    max-width: 950px;
    flex-basis: 100%;
}

.lgCard {
    min-width: 750px;
    flex-basis: 100%;
}

.xlCard {
    min-width: 900px;
    flex-basis: 100%;
}

@media (min-width: 768px) {
    .smCard {
        flex-basis: 48%;
    }
}

@media (min-width: 992px) {
    .smCard {
        flex-basis: 31%;
    }

    .mdCard {
        flex-basis: 48%;
    }
}

@media (min-width: 1200px) {
    .lgCard {
        flex-basis: 24%;
    }

    .smCard {
        flex-basis: 31%;
    }
}

/*
** Buttons
*/

.btn-outline-cc {
    color: #279f9f;
    border-color: #279f9f;
    border-radius: 8px;
}

.btn-outline-cc:hover {
    color: #fff;
    background-color: #279f9f;
    border-color: #279f9f;
}

.btn-outline-cc.focus,
.btn-outline-cc:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-cc.disabled,
.btn-outline-cc:disabled {
    color: #aaaaaa;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: #aaaaaa;
}

.btn-outline-cc:not(:disabled):not(.disabled).active,
.btn-outline-cc:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #279f9f;
    border-color: #279f9f;
}

.btn-outline-cc:not(:disabled):not(.disabled).active:focus,
.btn-outline-cc:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-cc.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn.btn-xs {
    font-size: 0.8em;
    padding: 0 0.3em;
}

/*
**  Live Search
*/

.search-label {
    position: relative;
}

.search-label input {
    width: 100%;
    padding: 6px;
    font-size: 20px;
    font-style: italic;
    color: #444;
    box-sizing: border-box;
    outline: none;
}

.search-icon {
    position: absolute;
    top: 12px;
    font-size: 20px;
    color: #999;
    right: 18px;
}

/*
**  Navigation
**
**  #main-navbar is the ID of the site's core navigation;
**  #sidebar-nav is the ID of a side panel navigation used on
**  pages with many features, such as the ProductType page.
*/

#main-navbar.navbar {
    box-sizing: border-box;
    font-size: 0.9rem;
    padding: 0px;
    margin: 0px 0px 20px 0px;
    border-bottom: 1px solid #eee;
    color: #666666;
}

#main-navbar .navbar-brand {
    padding: 10px 10px;
    margin: 0px;
    background-color: transparent;
    box-shadow: none;
}

#main-navbar a.nav-link:hover {
    color: #279f9f;
}

@media (min-width: 992px) {
    #main-navbar-nav .navbar-nav {
        line-height: 55px;
        height: 105px;
    }

    #main-navbar.navbar {
        height: 106px !important;
    }

    #main-navbar .nav-link.active {
        font-weight: 200;
        border-bottom: solid 2.5px #279f9f;
    }
}

#main-navbar-nav {
    padding-left: 10px;
    border-left: 1px solid #eee;
}

#section-navbar.navbar {
    font-size: 0.9rem;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #eee;
    color: #666666;
}

#section-navbar .nav-link {
    padding: 10px 0px;
    margin-right: 25px;
}

#section-navbar a.nav-link:hover {
    color: #279f9f;
}

#section-navbar .nav-link.active {
    font-weight: 200;
    border-bottom: solid 1.5px #279f9f;
}

/*
**  Errors
*/

.errors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 0.5rem 0;
    background-color: #fff3cd;
    color: #856404;
}

/*
** Alerts
*/

.alerts {
    display: flex;
    flex-wrap: wrap;
}

.alerts .alert {
    border: solid 1px #cccccc;
    border-radius: 0.75em;
    padding: 0 0.5em;
    margin-left: 0.2em;
    color: #279f9f;
}

.alerts .warning {
    color: #aaaa66;
}

.alerts .danger {
    color: #ff3333;
}

/*
**  Product Calendar
*/

.agenda {
    min-width: 300px;
}

.calevent {
    font-size: 0.7em;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

.calevent .eventtime {
    padding-top: 5px;
    display: inline-block;
}

.calevent .eventcapacity {
    padding-top: 5px;
    float: right;
}

/*
**  Departure states
*/

.dsOpen {
    color: #0aabbc;
}

.dsDeparted {
    font-style: italic;
    text-decoration: wavy underline;
    color: #0aabbc;
}

.dsDeparted div {
    -webkit-text-decoration: wavy underline;
    -moz-text-decoration: wavy underline;
    text-decoration: wavy underline;
}

.dsClosed {
    color: rgba(0, 0, 0, 0.5);
}

.dsCancelled {
    color: rgba(0, 0, 0, 0.25);
    text-decoration: line-through;
}

.badge-cc {
    background-color: #279f9f;
    color: #ffffff;
    border: 1px solid #ffffff;
}

.badge-cc-alert {
    color: #0aabbc;
    background-color: #ffffff;
    border: 1px solid #279f9f;
}

.badge-empty,
.badge-available,
.badge-soldout {
    border-width: 1px;
    width: 35px;
}

.badge-empty {
    border-style: none none;
}

.badge-available {
    border-style: solid solid;
}

.badge-soldout {
    border-style: solid;
    color: #ffffff;
}

.dsOpen .badge-soldout {
    background-color: #0aabbc;
}

.dsDeparted .badge-soldout {
    color: #0aabbc;
}

.dsClosed .badge-soldout {
    background-color: rgba(0, 0, 0, 0.5);
}

.dsCancelled .badge-soldout {
    background-color: rgba(0, 0, 0, 0.25);
}

.badge-circle {
    width: 14px;
    height: 14px;
    text-align: center;
    font-size: 8px;
    border-radius: 7px;
    position: relative;
    top: -7px;
    left: -5px;
}

/*
**  Order formats
*/

.smChar .lgChar {
    padding: 0;
    margin: 0;
}

.smChar {
    line-height: 1em;
    height: 12px;
    font-size: 12px;
    margin-top: 0px;
}

.lgChar {
    line-height: 30px;
    height: 30px;
    font-size: 30px;
    margin-top: -4px;
}

/*
**  WaterLevels
*/
/* .scrolly {
  display: block;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
} */

/*
**  Modals
*/
/*
.m_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.m_modal-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.m_display-block {
  display: block;
}

.m_display-none {
  display: none;
}
*/
/*
**  Popup
*/

.popup {
    position: fixed;
    top: 10px;
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 9999;
}

.popup .popuptext {
    position: relative;
    visibility: hidden;
    text-align: center;
    border-radius: 6px;
    background-color: #555;
    color: #fff;
    padding: 8px 10px;
}

.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 2.2s;
    animation: fadeIn 2.2s;
}

@-webkit-keyframes fadeIn {

    0%,
    100% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }
}

@keyframes fadeIn {

    0%,
    100% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }
}

/*
** Pagination
*/

.pagination {
    margin: 0;
}

.page-item.active .page-link {
    background-color: #666666;
    border-color: #333333;
}

.page-link {
    color: #666666;
}

.page-link:focus {
    outline: none;
    box-shadow: none;
}

/*
**  Landing pages
*/

.page-title {
    color: #495057;
    font-size: 1.5rem;
}

.component-title {
    color: #495057;
    font-size: 1.25rem;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

:root {
    --jumbotron-padding-y: 3rem;
}

.jumbotron {
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff;
}

@media (min-width: 768px) {
    .jumbotron {
        padding-top: calc(var(--jumbotron-padding-y) * 2);
        padding-bottom: calc(var(--jumbotron-padding-y) * 2);
    }
}

.jumbotron p:last-child {
    margin-bottom: 0;
}

.jumbotron-heading {
    font-weight: 300;
}

.jumbotron .container {
    max-width: 40rem;
}

footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

footer p {
    margin-bottom: 0.25rem;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.table td.text {
    max-width: 200px;
}

.table td.text span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

h1.public,
h2.public,
h3.public {
    font-family: "Work Sans", sans-serif, Helvetica, Arial, Verdana;
    font-weight: 200;
    text-transform: uppercase;
    color: #000000;
}

/*
**  Utilities
*/

.pointer {
    cursor: pointer;
}