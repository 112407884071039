/*
** styles.css
**
**  Styles for the timesheet component
*/

.frame {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 70px auto;
    grid-template-areas:
      ". dow1 dow2 dow3 dow4 dow5 dow6 dow7 ."
      "item cell1 cell2 cell3 cell4 cell5 cell6 cell7 total"
      "title title title title title title title title title "
      "scheduled sc1 sc2 sc3 sc4 sc5 sc6 sc7 scTotal"
      "worked wk1 wk2 wk3 wk4 wk5 wk6 wk7 wkTotal"
      "difference df1 df2 df3 df4 df5 df6 df7 dfTotal"
      "sp sp sp sp sp sp sp sp sp"
      "regular rt1 rt2 rt3 rt4 rt5 rt6 rt7 rtTotal"
      "overtime ot1 ot2 ot3 ot4 ot5 ot6 ot7 otTotal"
      "doubletime dt1 dt2 dt3 dt4 dt5 dt6 dt7 dtTotal"
      "holiday ht1 ht2 ht3 ht4 ht5 ht6 ht7 htTotal"
      "day dy1 dy2 dy3 dy4 dy5 dy6 dy7 dyTotal"
      "approve approve approve approve approve approve approve approve approve ";
      width:100%;
}

.dow1 {
    grid-area: dow1;
}
.dow2 {
    grid-area: dow2;
}
.dow3 {
    grid-area: dow3;
}
.dow4 {
    grid-area: dow4;
}
.dow5 {
    grid-area: dow5;
}
.dow6 {
    grid-area: dow6;
}
.dow7 {
    grid-area: dow7;
}
.item {
    grid-area:item;
}
.cell1 {
    grid-area: cell1;
}
.cell2 {
    grid-area: cell2;
}
.cell3 {
    grid-area: cell3;
}
.cell4 {
    grid-area: cell4;
}
.cell5 {
    grid-area: cell5;
}
.cell6 {
    grid-area: cell6;
}
.cell7 {
    grid-area: cell7;
}
.total {
    grid-area: total;
}
.scheduled{
    grid-area: scheduled;
}
.sc1 {
    grid-area: sc1;
}
.sc2 {
    grid-area: sc2;
}
.sc3 {
    grid-area: sc3;
}
.sc4 {
    grid-area: sc4;
}
.sc5 {
    grid-area: sc5;
}
.sc6 {
    grid-area: sc6;
}
.sc7 {
    grid-area: sc7;
}
.scTotal {
    grid-area: scTotal;
}
.worked{
    grid-area: worked;
}
.wk {
    border-bottom: 3px double #cccccc;
}
.wk1 {
    grid-area: wk1;
}
.wk2 {
    grid-area: wk2;
}
.wk3 {
    grid-area: wk3;

}
.wk4 {
    grid-area: wk4;
}
.wk5 {
    grid-area: wk5;
}
.wk6 {
    grid-area: wk6;
}
.wk7 {
    grid-area: wk7;
}
.wkTotal {
    grid-area: wkTotal;
}
.difference{
    grid-area: difference;
}
.df1 {
    grid-area: df1;
}
.df2 {
    grid-area: df2;
}
.df3 {
    grid-area: df3;
}
.df4 {
    grid-area: df4;
}
.df5 {
    grid-area: df5;
}
.df6 {
    grid-area: df6;
}
.df7 {
    grid-area: df7;
}
.dfTotal {
    grid-area: dfTotal;
}
.overtime{
    grid-area: overtime;
}
.ot {
    font-weight:600;
}
.ot1 {
    grid-area: ot1;
}
.ot2 {
    grid-area: ot2;
}
.ot3 {
    grid-area: ot3;
}
.ot4 {
    grid-area: ot4;
}
.ot5 {
    grid-area: ot5;
}
.ot6 {
    grid-area: ot6;
}
.ot7 {
    grid-area: ot7;
}
.otTotal {
    grid-area: otTotal;
}
.doubletime {
    grid-area:doubletime
}
.totals {
    font-weight: 600;
}
.journal {
    padding-right:4px;
}
.header {
    font-weight: 600;
}
.sum {
    padding-top:5px;
    border-top: 3px double #cccccc;
}
