.timeSlotContainer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
}

.timeSlotTour {
  display: inline-block;
  box-sizing: border-box;
  height: 43px;
  color: #ffffff;
  background: #0aabbc;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1em;
  text-align: center;
  padding: 7px 10px 7px 10px;
  margin: 2px 5px;
}

.timeSlotTour .time {
  margin-bottom: 5px;
}

.timeSlotTour .details {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}
